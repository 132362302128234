class Utility{
  constructor(){}
  removeClass(elements, myClass) {
    if (!elements) { return; }
    if (typeof(elements) === 'string') {
      elements = document.querySelectorAll(elements);
    }
    else if (elements.tagName) { elements=[elements]; }
    var reg = new RegExp('(^| )'+myClass+'($| )','g');
    for (var i=0; i<elements.length; i++) {
      elements[i].className = elements[i].className.replace(reg,' ');
    }
  }
  addClass(elements, myClass) {
    if (typeof(elements) === 'string') {
      elements = document.querySelectorAll(elements);
    }
    else if (elements.tagName) { elements=[elements]; }
    for (var i=0; i<elements.length; i++) {
      elements[i].classList.add(myClass);
    }
  }
  toggleClass(elements, myClass){
    if(this.hasClass(elements, myClass)){
      this.removeClass(elements, myClass);
    }else{
      this.addClass(elements, myClass);
    }
  }
  hasClass(elements, myClass) {
    if (typeof(elements) === 'string') {
      elements = document.querySelectorAll(elements);
    }
    else if (elements.tagName) { elements=[elements]; }
    for (var i=0; i<elements.length; i++) {
      if(elements[i].className.indexOf(myClass) > -1){
        return true;
      }else{
        return false;
      }
    }
  }
  timer (delay) {
    return new Promise( (resolve, reject) => {
      setTimeout( () => {
        resolve();
      }, delay);
    });
  }
  //フェード
  fade(elements, duration, direction, callback){
    var start = performance.now();
      requestAnimationFrame(function tick(timestamp) {
        let easing = (timestamp - start) / duration;
        let opacity;
        opacity = Math.min(easing, 1);
        if(direction == 'out'){
          opacity = 1 - opacity;
        }
        elements.style.opacity = opacity;
        if (easing < 1) {
            requestAnimationFrame(tick);
        } else {
            callback();
        }
    });
  }
  //属性の値を取得
  getAttr(element,attr){
    if(element.hasAttribute(attr)){
      return element.getAttribute(attr);
    }else{
      return '';
    }
  }
  //属性を削除
  removeAttr(elements,attr){
    let els = new Array();
    if (typeof(elements) === 'string') {
      els = document.querySelectorAll(elements);
    }
    for(var i=0; i<els.length; i++){
      if(els[i] !== null){
        if(els[i].hasAttribute(attr)){
          els[i].removeAttribute(attr);
        }
      }
    }
  }
  //属性をセット
  setAttr(elements,attr,val){
    let value = '';
    let els = new Array();
    if (typeof(elements) === 'string') {
      els = document.querySelectorAll(elements);
    }
    for(var i=0; i<els.length; i++){
      if(els[i] !== null){
        if(els[i].hasAttribute(attr)){
          value = els[i].getAttribute(attr);
          value += val;
        }else{
          value = val;
        }
        els[i].setAttribute(attr,value);
        value = '';
      }
    }
  }
  //styleの値を取得
  getStyleValue(element,style){
    const target = document.querySelector(element);
    if(target !== null){
      return window.getComputedStyle(target).getPropertyValue(style);
    }
    return null;
  }
  setCss(elements,attr,val){
    let els = new Array();
    if (typeof(elements) === 'string') {
      els = document.querySelectorAll(elements);
    }
    for(var i=0; i<els.length; i++){
      if(els[i] !== null){
        //els[i].style.attr = val;
      }
    }
  }

}

export default Utility;
