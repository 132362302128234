import Utility from './utility';
export default class Drawer extends Utility{
  constructor() {
    super();

    this.isStatus = 'close';
    this.isAvailMenuBtn = true;
    this.elBody = document.querySelector('body');
    this.elToggleBtn = document.querySelector('.toggle-btn');
    this.elCloseBtn = document.querySelector('.close-btn');
    this.winW = window.innerWidth;
    this.scrollFixed = 0;
    this.beforTopPos = 0;

    this.setListener();
  }

  close(){
    //console.log('close');
    this.removeClass(this.elBody,'nav-open');
    this.addClass(this.elBody,'nav-close');
    //子メニューのeventをoff
    // $('.menu-item-has-children').off();

    // スクロールの有効化
    const targetEl = ['body','html','.site','.main-navigation','.pagetop','.toggle-btn','.site-content'];
    this.removeAttr(targetEl,'style');
    window.scrollTo(0, this.scrollFixed);

    //レイヤーを削除
    this.fade(document.querySelector('.menu-layer'),500,'out',()=>{
      //callback
      //console.log('fadeout');
      document.querySelector('.menu-layer').remove();
      this.isStatus = 'close';
      this.isAvailMenuBtn = true;
    });
    //header固定
    // if(topPos < 90) $('.site-header').removeClass('fixed');
  }

  open(){
    //console.log('open');
    if(!this.hasClass('.site-header','fixed')){
      this.addClass('.site-header','fixed');
    }

    this.timer(650)
    .then(() => {
      //console.log("menu open 終了");
      this.isStatus = 'open';
      this.isAvailMenuBtn = true;
    });

    //背景のスクロールを固定
    this.winW = window.innerWidth;
    this.scrollFixed = document.documentElement.scrollTop || document.body.scrollTop;
    document.querySelector('.site-content').setAttribute('style','position:relative;top:'+(-1*this.scrollFixed)+'px;');

    //スクロールバーの幅の分、トグルボタンとページトップを位置調整する
    let paddingR = this.winW - document.body.clientWidth;
    if(paddingR > 0) {
      const bodyPaddingR = `padding-right:${paddingR}px;`;
      this.setAttr(['body'],'style',bodyPaddingR);
      let pagetopRight = 0;
      if(document.querySelector('.pagetop') !== null){
        pagetopRight = this.getStyleValue('.pagetop','right');
        pagetopRight = parseInt(pagetopRight) + paddingR;
        document.querySelector('.pagetop').style.right = `${pagetopRight}px`;
      }
      let toggleRight = 0;
      if(document.querySelector('.toggle-btn') !== null){
        toggleRight = this.getStyleValue('.toggle-btn','right');
        toggleRight = parseInt(toggleRight) + paddingR;
        document.querySelector('.toggle-btn').style.right = `${toggleRight}px`;
      }
    }

    // スクロールの無効化
    this.setAttr(['body','html'],'style','overflow:hidden;height:100%;');
    this.beforTopPos = this.scrollFixed;

    //背面レイヤーの作成
    const layer = document.createElement('div');
    layer.classList.add('menu-layer');
    const elements = document.getElementsByClassName('menu-layer');
    if ( elements.length > 0 ) {
      document.querySelector('.menu-layer').remove();
    }
    document.querySelector('.main-navigation').appendChild(layer);
    this.fade(document.querySelector('.menu-layer'),300,'in',()=>{
      //callback
      //console.log('fadein');
      //this.scrollFixed = topPos;
      document.querySelector('.menu-layer').addEventListener('click',(el) => {
        this.toggleMenu();
      });
    });

    //遅延させてからメニューを開く
    this.timer(50)
    .then(() => {
      this.removeClass(this.elBody,'nav-close');
      this.addClass(this.elBody,'nav-open');
    });

    //子メニュー開閉
    // $('.menu-item-has-children').on('click touch', function () {
    //   $(this).toggleClass('open-sub-menu');
    // });

  }

  setListener () {
    if(this.elToggleBtn !== null){
      //メニュー開閉ボタン
      this.elToggleBtn.addEventListener('click', (el) => {
        this.toggleMenu();
      });

      //メニュー内closeボタン
      // this.elCloseBtn.addEventListener('click',(el) => {
      //   this.toggleMenu();
      // });

      //ページ内リンクをclickで閉じる
      [...document.querySelectorAll('.main-navigation a')].forEach((el, index) => {
        let link = this.getAttr(el,'href');
        if(link.startsWith('#')){
          el.addEventListener('click', (elBtn) => {
            if(this.isStatus === 'open'){
              this.toggleMenu();
            }
          });
        }
      });
    }
  }

  toggleMenu () {
    if(this.isAvailMenuBtn){
      this.isAvailMenuBtn = false;
      this.toggleClass(this.elToggleBtn,'is-open');
      if (this.isStatus === 'open') {//閉じる
        this.close();
      }else{
        this.open();
      }
    }
  }

}
