import '../styles/index.scss';
import sal from 'sal.js';
import Smoothscroll from 'smooth-scroll';
import Utility from './utility.js';
import Drawer from './drawer.js';

const ut = new Utility();
const drawer = new Drawer;

//iframeの高さ変更
if (process.env.NODE_ENV === 'development') {
  require('../index.html');
}

var contactHeight = 0;

window.addEventListener('message', function(e) {
  var iframe = document.getElementById("mailform");
  
  var eventName = e.data[0];
  var data = e.data[1];
  switch(eventName) {
    case 'setHeight':
      iframe.style.height = data+'px';
      contactHeight = data;
      break;
    case 'scrollToForm':
      iframe.scrollIntoView(true);
      break;
  }
    
}, false);

//sal.js
sal({
  threshold: 0.2,
});

//Smoothscroll
var scroll = new Smoothscroll('a[href*="#"]', {
  speed: 200,
  easing: 'easeInOutCubic',
  updateURL: false
});

//iOSでスクロール無効対策
var movefun = function (event) {
  event.preventDefault();
};

//
let winW = document.documentElement.clientWidth;
let winH = document.documentElement.clientHeight;
let is_fullmode = false;
let isScrolling = false;
const breakpoint = 768;
const breakpointPC = 1400;
if (winW > breakpoint) is_fullmode = true;

let beforePos, topPos, fixedPoint, scrollTO,contactPointStart,contactPointEnd;
topPos = window.pageYOffset;




//Resize---------------------------------
window.addEventListener('resize', resizeHandler);

function resizeHandler() {
  function delayed() {
    resize();
    resizeTimeout = null;
  }
  if (typeof resizeTimeout != 'undefined') {
    clearTimeout(resizeTimeout);
  }
  let resizeTimeout = setTimeout(delayed, 300);
}

function resize() {
  let beforeWin = winW;
  winW = document.documentElement.clientWidth;
  winH = document.documentElement.clientHeight;
  //横幅が変わった時だけ処理をする
  if (beforeWin != winW) {
    if (winW > breakpoint) {
      is_fullmode = true;
    } else {
      is_fullmode = false;
    }
    //下からボタンが出てくるタイミング
    if(is_fullmode){
      fixedPoint = winH;
    }else{
      fixedPoint = winH/2;
    }
    //SPメニュー閉じる
    if (drawer.isStatus === 'open') {
      drawer.toggleMenu();
    }
  }
}

//Load---------------------------------
window.addEventListener('load', function(e) {
  // console.log('load')
});